import React, { useState, forwardRef, useImperativeHandle, useEffect, useRef  } from 'react';
import { useSelector } from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import { Offcanvas } from 'react-bootstrap';
import axios from 'axios';


const EditEmployeeOffcanvas = forwardRef((props, ref) => {
  const firstCompany = useSelector(state => (state.company && state.company.firstCompany) || '');
    const { selectedEmployee, setDataUpdateTrigger, setEventsUpdateTrigger, setDataUpdateTrigger2 } = props;
    const [editEmploye , setEditEmploye] = useState(false);
    const [employeeData, setEmployeeData] = useState(selectedEmployee ? selectedEmployee : {});
    const [initialEmail, setInitialEmail] = useState('');
    const [hostelsData, setHostels] = useState([]);
    const [Rooms, setRooms] = useState([]);
    const [tovarnyData, setTovarny] = useState([]);
    const [selectedSelect, setSelectedSelect] = useState(null);
    const [selectedTovarna, setSelectedTovarna] = useState(null);
    const [selectedRoom, setSelectedRoom] = useState(null);
    
    const [selectedHostelId, setSelectedHostelId] = useState('')
    const [selectedTovarnaId, setSelectedTovarnaId] = useState('')

  useEffect(() => {
    fetchDataPokoje();
    fetchData();
    fetchDataTovarny();
    console.log('edit Emp - selectedEmployee', selectedEmployee);
    console.log('edit Emp - employeeData 1', employeeData);
  }, [firstCompany, selectedSelect, selectedEmployee, employeeData]);

  const fetchDataTovarny = async () => { 
    
    try {
      const response = await fetch(`/${firstCompany}/tovarny.json?${Date.now()}`);
      const data = await response.json();
      const newData = [...data, { id: '-', name: '-' }];
      setTovarny(newData);
    } catch (error) {
      console.error('Ошибка при загрузке данных:', error);
    }
  };

  const fetchDataPokoje = async (selectedHostelname) => {
    
      try {
        const response = await fetch(`/${firstCompany}/pokoje.json?${Date.now()}`);
        const data = await response.json();

        const response2 = await fetch(`/${firstCompany}/file.json?${Date.now()}`);
        const data2 = await response2.json();
        console.log('employeeData -- Edit employee', employeeData);
        
        const selectedHostel = selectedHostelname || employeeData.ubytovna;
        const filteredRooms = data.filter(
          (room) =>
            room.ubytovna === selectedHostel 
            
        );

        console.log('edit Emp - employeeData 2', employeeData);

        console.log('filteredRooms', filteredRooms);

        const roomCount = {};

    data2.forEach((client) => {
      if (client.ubytovna === selectedSelect) {
        const key = `${client.ubytovna} *-* ${client.room}`;
        if (!roomCount[key]) {
          roomCount[key] = 1;
        } else {
          roomCount[key]++;
        }
      }
    });

    console.log('roomCount', roomCount);
  
    const numberOfKeys = Object.keys(roomCount).length;

    if (numberOfKeys > 0) {
      
    const roomCountArray = Object.keys(roomCount).map((key) => {
      const [ubytovna, room] = key.split(' *-* ');
      return { ubytovna, room, count: roomCount[key] };
    });

    console.log('1-roomCountArray', roomCountArray);

    const roomsWithFreeSpace = filteredRooms.map((room) => {
      const countObj = roomCountArray.find((obj) => obj.ubytovna === room.ubytovna && obj.room === room.id);
      if (countObj) {
          const quantity = parseInt(room.quantity, 10);
          const count = countObj.count;
          const freeSpace = quantity - count;
          return { ...room, freeSpace };
      } else {
          // Если не удалось найти соответствующий объект в roomCountArray, то считаем, что свободных мест нет.
          return { ...room, freeSpace: room.quantity };
      }
  });
  
  console.log('roomsWithFreeSpace', roomsWithFreeSpace);

  const RoomsWithSpace = roomsWithFreeSpace.filter((room) => room.freeSpace > 0);

  console.log('RoomsWithSpace', RoomsWithSpace);
  console.log('edit Emp - employeeData 3', employeeData);
setRooms(RoomsWithSpace);
} else {
  console.log('edit Emp - employeeData 4', employeeData);
setRooms(filteredRooms);
console.log('filteredRooms-filteredRooms',filteredRooms);
}
        // setRooms(data);
      } catch (error) {
        console.error('Ошибка при загрузке данных:', error);
      }
    };



  const fetchData = async () => {
    try {
      const response = await fetch(`/${firstCompany}/ubytovny.json?${Date.now()}`);
      const data = await response.json();
      setHostels(data);
    } catch (error) {
      console.error('Ошибка при загрузке данных:', error);
    }
  };

    useEffect(() => {
        setEmployeeData(selectedEmployee || {});
        setInitialEmail(selectedEmployee?.email || ''); // Сохраняем email работника в состояние
        fetchDataPokoje();
        console.log('edit Emp - employeeData 5', employeeData);
      }, [selectedEmployee]);


      const handleChange = (e) => {
        const { name, value } = e.target;
       
        setEmployeeData((prevData) => ({ ...prevData, [name]: value }));
      }; 

    useImperativeHandle(ref, () => ({
        showEditEmployeModal() {
            setEditEmploye(true)
        }    
    }));
    const nav = useNavigate();


    const handleEditSubmit = async (e) => {
      e.preventDefault();
      const currentTime = new Date().toISOString();
      try { 
        const email = employeeData.email;
        const url = `https://test1.jobsi.app/update_data.php`;
    
        console.log(' Ed selectedSelect', selectedSelect);
      console.log(' Ed -- selectedTovarna', selectedTovarna);  

        // Добавляем старый email к данным сотрудника
        employeeData.oldEmail = initialEmail;
        employeeData.strediskoName = selectedTovarna === null ? selectedEmployee.stredisko : employeeData.stredisko;
        employeeData.ubytovnaName = selectedSelect === null ? selectedEmployee.ubytovna : employeeData.ubytovna;
        employeeData.room = selectedRoom === null ? selectedEmployee.room : employeeData.room;
        employeeData.ubytovna = (selectedHostelId === null || selectedHostelId === '') ? selectedEmployee.hostelId : selectedHostelId;
        employeeData.stredisko = selectedTovarnaId === null ? selectedEmployee.tovarnaId : selectedTovarnaId;

        console.log('Edit Emp - employeeData.hostelId', employeeData.hostelId);
        console.log('Edit Emp - selectedEmployee.hostelId ', selectedEmployee.hostelId );
        console.log('Edit Emp - employeeData.ubytovna', employeeData.ubytovna);
        console.log('Edit Emp - selectedHostelId', selectedHostelId);
        console.log('Edit Emp - true, selectedTovarnaId 0', selectedTovarnaId);
        console.log('Edit Emp - true, selectedEmployee.tovarnaId 1', selectedEmployee.tovarnaId);
        console.log('Edit Emp - true, employeeData.stredisko 2', employeeData.stredisko );
        if (selectedTovarnaId === '-') {
          employeeData.stredisko = selectedTovarnaId;
          console.log('Edit Emp - true, selectedTovarnaId', selectedTovarnaId);
        }

      console.log(' EDIT EmployeeOffcanvas -- hostelName', selectedSelect);
      console.log(' EmployeeOffcanvas -- tovarnaName', selectedTovarna);

        console.log('Edit Employe Canvas -- employeeData', employeeData);
        console.log('firstCompany', firstCompany); 
    
        const response = await axios.put(url, { employeeData, firstCompany, currentTime });
        // Передача объекта { email, employeeData } в качестве тела запроса
    
        if (response.status === 200) {
          // Запрос выполнен успешно, обновляем компонент
          setDataUpdateTrigger((prev) => !prev); 
          setEventsUpdateTrigger((prev) => !prev);
          setDataUpdateTrigger2((prev) => !prev);
          console.log('Данные успешно обновлены');
        } else {
          // Обработка ошибок, если запрос вернул ошибку
          console.error('Ошибка при обновлении данных:', response.data.message);
        }
      } catch (error) {
        console.error('Ошибка при выполнении запроса:', error);
      }
    
      setEditEmploye(false);
    };
    
  


    const handleUbytovnaSelect = (event) => {
      const selectedHostelName = event.target.value;
      const selectedHostel = hostelsData.find(hostel => hostel.id === selectedHostelName);
      setSelectedHostelId(selectedHostelName ? selectedHostelName : '-');
      setSelectedSelect(selectedHostel.name);
      setEmployeeData(prevData => ({ ...prevData, ubytovna: selectedHostel.name }));
      console.log('selectedHostel', selectedHostel);
      console.log('selectedHostelName', selectedHostelName);
      console.log('selectedHostel.name', selectedHostel.name);
      setSelectedRoom('-');
      setEmployeeData(prevData => ({
        ...prevData,
        room: '-'
      }));
      fetchDataPokoje(selectedHostel.name);
  };

    const handleTovarnaSelect = (event) => {
      const selectedTovarnaName = event.target.value;
      const selectedTovarna = tovarnyData.find(tovarna => tovarna.id === selectedTovarnaName);
      let tovarnaNameCheck = selectedTovarnaName ? selectedTovarnaName : '-';
      setSelectedTovarnaId(tovarnaNameCheck);
      console.log('tovarnaNameCheck', tovarnaNameCheck);
      setSelectedTovarnaId(tovarnaNameCheck === "-" ? "-" : tovarnaNameCheck);
      setSelectedTovarna(selectedTovarna.name);
      console.log('selectedTovarnaName', selectedTovarnaName);
      setEmployeeData(prevData => ({
        ...prevData,
        stredisko: selectedTovarna.name
      }));
    };

    const handleRoomSelect = (event) => {
      const room = event.target.value;
      setSelectedRoom(room);
      setEmployeeData(prevData => ({
        ...prevData,
        room: room
      }));
    };


    return (
        <>
            <Offcanvas show={editEmploye} onHide={setEditEmploye} className="offcanvas-end customeoff" placement='end'>
				<div className="offcanvas-header">
					<h5 className="modal-title" id="#gridSystemModal">Upravit pracovníka</h5>
					<button type="button" className="btn-close" 
						onClick={()=>setEditEmploye(false)}
					>
						<i className="fa-solid fa-xmark"></i>
					</button>
				</div>
				<div className="offcanvas-body">
                    <div className="container-fluid">
                        
                        <form onSubmit={handleEditSubmit}>
                            <div className="row">
                                
                                <div className="col-xl-6 mb-3">
                                    <label htmlFor="exampleFormControlInput2" className="form-label">Jméno <span className="text-danger">*</span></label>
                                    <input type="text" name="name" value={employeeData.name} onChange={handleChange} className="form-control" id="exampleFormControlInput2" required placeholder="" />
                                </div>	
                                <div className="col-xl-6 mb-3">
                                    <label htmlFor="exampleFormControlInput3" className="form-label">Email <span className="text-danger">*</span></label>
                                    <input type="email" name="email" value={employeeData.email} onChange={handleChange} className="form-control" id="exampleFormControlInput3" required placeholder="" />
                                </div>

                                
                                
                                <div className="col-xl-6 mb-3 DFlex ubytovnaP">

<div style={{ width: '65%' }}>
                                <label className="form-label">Ubytovna <span className="text-danger">*</span></label>
                                <select name="ubytovna" value={employeeData.ubytovna} onChange={handleUbytovnaSelect}  className="default-select form-control" required>
                                     <option data-display="Select">{employeeData.ubytovna}</option> 
                                    {hostelsData && hostelsData.map((hostel, index) => (
                                        <option key={index} value={hostel.id}>{hostel.name}</option>
                                    ))}
                                </select>
                            </div>

                           
                            <div style={{ width: '30%' }}>
                              <label className="form-label">Pokoj</label>
                              <select name="pokoj" onChange={handleRoomSelect} value={employeeData.room} className="default-select form-control">
                                 <option data-display="Select">{employeeData.room}</option> 
                                {Rooms.length > 0 && (
                                  Rooms.map((room, index) => (
                                    <option
                                      key={index}
                                      value={room.id}
                                    >
                                      {`Pokoj č. ${room.name} (volno: ${room.freeSpace})`}
                                    </option>
                                  ))
                                )}
                              </select>
                            </div>
                            </div>

                            <div className="col-xl-6 mb-3">
        <label className="form-label">Středisko</label>
        <select
          name="stredisko"
          className="default-select form-control"
          onChange={handleTovarnaSelect}
          value={employeeData.stredisko}
        >
          <option data-display="Select">{employeeData.stredisko === '-' ? "Nepracuje" : employeeData.stredisko}</option>
          {tovarnyData.map((tovarna, index) => (
            <option key={index} value={tovarna.id}>
              {tovarna.name === '-' ? "Nepracuje" : tovarna.name} 
            </option>
          ))}
        </select>
      </div>
     


                                <div className="col-xl-6 mb-3">
                                    <label className="form-label">Národnost <span className="text-danger">*</span></label>
                                    <select name="country" value={employeeData.country} onChange={handleChange} className="default-select form-control" required>
                                        <option  data-display="Select">Prosím vyberte</option>
                                        <option value="CZ">CZ</option>
                                        <option value="UA">UA</option>
                                        <option value="PL">PL</option>
                                        <option value="LT">LT</option>
                                        <option value="LT">LV</option>
                                        <option value="RO">RO</option>
                                        <option value="MD">MD</option>
                                        <option value="HU">HU</option>
                                        <option value="AZ">AZ</option>
                                        <option value="SK">SK</option>
                                        <option value="BG">BG</option>
                                    </select>
                                </div>
                                <div className="col-xl-6 mb-3">
                                    <label htmlFor="exampleFormControlInput88" className="form-label">Mobile <span className="text-danger">*</span></label>
                                    <input name="mobile" value={employeeData.mobile} onChange={handleChange} type="number" className="form-control" id="exampleFormControlInput88" required placeholder="" />
                                </div>
                                <div className="col-xl-6 mb-3">
                                    <label className="form-label">Pohlaví <span className="text-danger">*</span></label>
                                    <select name="gender" value={employeeData.gender} onChange={handleChange} className="default-select form-control" required>
                                        <option  data-display="Select">Prosím vyberte</option>
                                        <option value="Mužský">Mužský</option>
                                        <option value="Ženský">Ženský</option>
                                    </select>
                                </div>
                                <div className="col-xl-6 mb-3">
                                    <label htmlFor="exampleFormControlInput99" className="form-label">Datum registrace</label>                                    
                                    <input
      type="text"
      id="registr"
      name="registr"
      className="inputReg form-control"
      value={employeeData.registr}
      disabled
    /> 
                                </div>
                                <div className="col-xl-6 mb-3">
                                    <label htmlFor="exampleFormControlInput8" className="form-label">Datum narození</label>                                    
                                    <input name="birth" value={employeeData.birth} onChange={handleChange} type="date" className="form-control" id="exampleFormControlInput88"   /> 
                                </div>
                                <div className="col-xl-6 mb-3">
                                    <label htmlFor="exampleFormControlInput88" className="form-label">Poznámky</label>
                                    <input name="note" value={employeeData.note} onChange={handleChange} type="text" className="form-control" id="exampleFormControlInput88" placeholder="" />
                                </div>
                                
                            </div>
                           
                            <div style={{marginTop: '30px'}}>
                              <img style={{width: '100%'}} src="/Transferworker.webp"></img>
                            </div>

                            <div style={{marginTop: '30px', marginBottom: '50px'}}>
                                <button type="submit" className="btn btn-primary me-1">Upravit</button>
                                <Link to={"#"} onClick={()=>setEditEmploye(false)} className="btn btn-danger light ms-1">Zrušit</Link>
                            </div>
                        </form>
                    </div>
				</div>
			</Offcanvas>     
        </>
    );
});

export default EditEmployeeOffcanvas;